import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES, START_TIME_REGISTER_POPUP } from 'shared/constants';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/close_3.svg';
import { getJwt } from 'shared/helpers';
import { useTranslate } from 'shared/hooks';
import styles from './styles.module.scss';

let timeId: NodeJS.Timeout | null = null;

export function ShowRegisterPopup(): JSX.Element | null {
  const jwt = getJwt();
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslate();

  useEffect(() => {
    if (jwt ?? timeId !== null) return;

    timeId = setTimeout(() => {
      setShowPopup(true);
    }, START_TIME_REGISTER_POPUP);
  }, [jwt]);

  const closePopup = (): void => {
    setShowPopup(false);
  };

  if (!showPopup) return null;

  return (
    <div className={styles.container}>
      <div className={styles.container_close_icon_container}>
        <CloseIcon onClick={closePopup} />
      </div>
      <div className={styles.container_popup}>
        <h3 className={styles.container_title}>{t('register_and_bonuses')}</h3>
        <div className={styles.container_action_container}>
          <Link className={styles.container_register} to={ROUTES.register.to}>
            {t('registration2')}
          </Link>
        </div>
      </div>
    </div>
  );
}
